import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Menu,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box
} from '@mui/material';
import {
  Dashboard as DashboardIcon,
  Menu as MenuIcon,
  ListAlt as ListAltIcon,
  AccountBalanceWallet as AccountBalanceWalletIcon,
  Functions as FunctionsIcon,
  Settings as SettingsIcon,
  Brightness4 as Brightness4Icon,
  ExitToApp as ExitToAppIcon,
  ExpandMore as ExpandMoreIcon,
  Calculate as CalculateIcon,
  CurrencyBitcoin as CurrencyBitcoinIcon,
  AccountCircle as AccountCircleIcon,
  Whatshot as WhatshotIcon,
  EventNote as EventNoteIcon,
  CalendarToday as CalendarTodayIcon,
  EventAvailable as EventAvailableIcon,
  Event as EventIcon,
  Troubleshoot as TroubleshootIcon,
  Group as GroupIcon,
  Gavel as GavelIcon,
  QueryStats as QueryStatsIcon,
  Compare as CompareIcon,
  // SsidChart as SsidChartIcon
} from '@mui/icons-material';
import SearchBar from './SearchBar';
import { useAuth } from './AuthContext';
import { useTheme } from '@mui/material/styles';

const AuthenticatedLayout = ({ children, darkMode, setDarkMode }) => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const handleDrawerOpen = () => setDrawerOpen(true);
  const handleDrawerClose = () => setDrawerOpen(false);
  const handleMenuOpen = (event) => setMenuAnchorEl(event.currentTarget);
  const handleMenuClose = () => setMenuAnchorEl(null);

  const theme = useTheme();

  const handleLogout = () => {
    logout()
      .then(() => {
        window.location.assign('/');
      })
      .catch(error => {
        console.error('Error logging out:', error);
      });
  };

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            aria-label="menu"
            onClick={handleDrawerOpen}
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.2)',
              },
              borderRadius: 1,
              padding: '8px',
              marginRight: 1,
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, cursor: 'pointer' }}
            onClick={() => navigate('/')}
          >
            MarketRodeo
          </Typography>
          <Box sx={{ mx: 1 }} />
          <SearchBar />
          <Button onClick={handleMenuOpen}>
            <AccountCircleIcon sx={{ color: theme.palette.secondary.main, fontSize: 30 }} />
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={handleDrawerClose}
      // sx={{ '& .MuiDrawer-paper': { backgroundColor: '#1976d2' } }}
      >
        <List>
          <ListItemButton component={Link} to="/" onClick={handleDrawerClose}>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItemButton>
          <ListItemButton component={Link} to="/portfolio" onClick={handleDrawerClose}>
            <ListItemIcon>
              <AccountBalanceWalletIcon />
            </ListItemIcon>
            <ListItemText primary="Portfolio" />
          </ListItemButton>
          <ListItemButton component={Link} to="/watchlist" onClick={handleDrawerClose}>
            <ListItemIcon>
              <ListAltIcon />
            </ListItemIcon>
            <ListItemText primary="Watchlist" />
          </ListItemButton>

          <ListItemButton component={Link} to="/lists" onClick={handleDrawerClose}>
            <ListItemIcon>
              <ListAltIcon />
            </ListItemIcon>
            <ListItemText primary="Lists" />
          </ListItemButton>

          <ListItemButton component={Link} to="/asset-comparison" onClick={handleDrawerClose}>
            <ListItemIcon>
              <CompareIcon />
            </ListItemIcon>
            <ListItemText primary="Asset Comparison" />
          </ListItemButton>

          <ListItemButton component={Link} to="/market-movers" onClick={handleDrawerClose}>
            <ListItemIcon>
              <WhatshotIcon />
            </ListItemIcon>
            <ListItemText primary="Market Movers" />
          </ListItemButton>
{/*
          <Accordion sx={{ background: 'transparent', boxShadow: 'none' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <ListItemIcon>
                <FunctionsIcon />
              </ListItemIcon>
              <ListItemText primary="Market Movers" />
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              <List sx={{ paddingLeft: 2, paddingTop: 0 }}>
                <ListItemButton component={Link} to="/market-gainers" onClick={handleDrawerClose}>
                  <ListItemIcon>
                    <TrendingUpIcon />
                  </ListItemIcon>
                  <ListItemText primary="Market Gainers" />
                </ListItemButton>
                <ListItemButton component={Link} to="/market-losers" onClick={handleDrawerClose}>
                  <ListItemIcon>
                    <TrendingDownIcon />
                  </ListItemIcon>
                  <ListItemText primary="Market Losers" />
                </ListItemButton>
                <ListItemButton component={Link} to="/market-actives" onClick={handleDrawerClose}>
                  <ListItemIcon>
                    <WhatshotIcon />
                  </ListItemIcon>
                  <ListItemText primary="Market Actives" />
                </ListItemButton>
                 <ListItemButton component={Link} to="/market-extended-hours" onClick={handleDrawerClose}>
                  <ListItemIcon>
                    <SsidChartIcon />
                  </ListItemIcon>
                  <ListItemText primary="Extended Hours" />
                </ListItemButton> 
              </List>
            </AccordionDetails>
          </Accordion>
*/}
          <Accordion sx={{ background: 'transparent', boxShadow: 'none' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <ListItemIcon>
                <FunctionsIcon />
              </ListItemIcon>
              <ListItemText primary="Screeners" />
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              <List sx={{ paddingLeft: 2, paddingTop: 0 }}> {/* Adjust paddingLeft here */}
                <ListItemButton component={Link} to="/stock-custom-screener" onClick={handleDrawerClose}>
                  <ListItemIcon>
                    <FunctionsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Market Screener" />
                </ListItemButton>
                <ListItemButton component={Link} to="/quotes-screener" onClick={handleDrawerClose}>
                  <ListItemIcon>
                    <FunctionsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Quotes Screener" />
                </ListItemButton>
                <ListItemButton component={Link} to="/avg-screener" onClick={handleDrawerClose}>
                  <ListItemIcon>
                    <FunctionsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Avg Screener" />
                </ListItemButton>
              </List>
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ background: 'transparent', boxShadow: 'none' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <ListItemIcon>
                <CurrencyBitcoinIcon />
              </ListItemIcon>
              <ListItemText primary="Crypto" />
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              <List sx={{ paddingLeft: 2, paddingTop: 0 }}> {/* Adjust paddingLeft here */}
                <ListItemButton component={Link} to="/crypto-quotes-screener" onClick={handleDrawerClose}>
                  <ListItemIcon>
                    <ListAltIcon />
                  </ListItemIcon>
                  <ListItemText primary="Crypto Screener" />
                </ListItemButton>
                <ListItemButton component={Link} to="/crypto-avg-screener" onClick={handleDrawerClose}>
                  <ListItemIcon>
                    <ListAltIcon />
                  </ListItemIcon>
                  <ListItemText primary="Crypto Avg Screener" />
                </ListItemButton>
                {/* <ListItemButton component={Link} to="/CryptoPriceTracker" onClick={handleDrawerClose}>
                  <ListItemIcon>
                    <ListAltIcon />
                  </ListItemIcon>
                  <ListItemText primary="Crypto Price Tracker" />
                </ListItemButton>
                 <ListItemButton component={Link} to="/CryptoPriceTracker" onClick={handleDrawerClose}>
                  <ListItemIcon>
                    <ListAltIcon />
                  </ListItemIcon>
                  <ListItemText primary="Crypto Screener" />
                </ListItemButton>
                <ListItemButton component={Link} to="/CryptoAllMarketData" onClick={handleDrawerClose}>
                  <ListItemIcon>
                    <ListAltIcon />
                  </ListItemIcon>
                  <ListItemText primary="Binance All Market Data" />
                </ListItemButton>
                <ListItemButton component={Link} to="/CoinbaseAllMarketData" onClick={handleDrawerClose}>
                  <ListItemIcon>
                    <ListAltIcon />
                  </ListItemIcon>
                  <ListItemText primary="Coinbase All Market Data" />
                </ListItemButton>
                <ListItemButton component={Link} to="/BitvavoAllMarketData" onClick={handleDrawerClose}>
                  <ListItemIcon>
                    <ListAltIcon />
                  </ListItemIcon>
                  <ListItemText primary="Bitvavo All Market Data" />
                </ListItemButton>
                <ListItemButton component={Link} to="/KrakenAllMarketData" onClick={handleDrawerClose}>
                  <ListItemIcon>
                    <ListAltIcon />
                  </ListItemIcon>
                  <ListItemText primary="Kraken All Market Data" />
                </ListItemButton>
                <ListItemButton component={Link} to="/ByBitAllMarketData" onClick={handleDrawerClose}>
                  <ListItemIcon>
                    <ListAltIcon />
                  </ListItemIcon>
                  <ListItemText primary="ByBit All Market Data" />
                </ListItemButton>
                <ListItemButton component={Link} to="/CryptoArbitrageScanner" onClick={handleDrawerClose}>
                  <ListItemIcon>
                    <FunctionsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Crypto Arbitrage Scanner" />
                </ListItemButton>
                <ListItemButton component={Link} to="/AllTopCryptosData" onClick={handleDrawerClose}>
                  <ListItemIcon>
                    <FunctionsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Crypto Price Tracker" />
                </ListItemButton> */}
              </List>
            </AccordionDetails>
          </Accordion>
          {/*<ListItemButton component={Link} to="/Stock" onClick={handleDrawerClose}>
                    <ListItemIcon>
                      <ListAltIcon />
                    </ListItemIcon>
                    <ListItemText primary="Stock Profile" />
                  </ListItemButton> */}
          {/*     <Accordion sx={{ background: 'transparent', boxShadow: 'none' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <ListItemIcon>
                <FunctionsIcon />
              </ListItemIcon>
              <ListItemText primary="Options" />
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              <ListItemButton component={Link} to="/OptionChain" onClick={handleDrawerClose}>
                <ListItemIcon>
                  <FunctionsIcon />
                </ListItemIcon>
                <ListItemText primary="Option Chain" />
              </ListItemButton>

              <ListItemButton component={Link} to="/OptionsCalculator" onClick={handleDrawerClose}>
                <ListItemIcon>
                  <FunctionsIcon />
                </ListItemIcon>
                <ListItemText primary="Options Calculator" />
              </ListItemButton>
            </AccordionDetails>
          </Accordion>

   */}

          <Accordion sx={{ background: 'transparent', boxShadow: 'none' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <ListItemIcon>
                <GavelIcon />
              </ListItemIcon>
              <ListItemText primary="Congress" />
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              <ListItemButton component={Link} to="/congress-tracker" onClick={handleDrawerClose}>
                <ListItemIcon>
                  <TroubleshootIcon />
                </ListItemIcon>
                <ListItemText primary="Congress Tracker" />
              </ListItemButton>
              <ListItemButton component={Link} to="/congress-members" onClick={handleDrawerClose}>
                <ListItemIcon>
                  <GroupIcon />
                </ListItemIcon>
                <ListItemText primary="Congress Members" />
              </ListItemButton>
            </AccordionDetails>
          </Accordion>

          <Accordion sx={{ background: 'transparent', boxShadow: 'none' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <ListItemIcon>
                <QueryStatsIcon />
              </ListItemIcon>
              <ListItemText primary="Trackers" />
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              <ListItemButton component={Link} to="/insider-tracker" onClick={handleDrawerClose}>
                <ListItemIcon>
                  <TroubleshootIcon />
                </ListItemIcon>
                <ListItemText primary="Insider Trading Tracker" />
              </ListItemButton>
            </AccordionDetails>
          </Accordion>

          <Accordion sx={{ background: 'transparent', boxShadow: 'none' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <ListItemIcon>
                <EventNoteIcon />
              </ListItemIcon>
              <ListItemText primary="Calendars" />
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              <ListItemButton component={Link} to="/earnings-calendar" onClick={handleDrawerClose}>
                <ListItemIcon>
                  <CalendarTodayIcon />
                </ListItemIcon>
                <ListItemText primary="Earnings Calendar" />
              </ListItemButton>
              <ListItemButton component={Link} to="/dividend-calendar" onClick={handleDrawerClose}>
                <ListItemIcon>
                  <EventAvailableIcon />
                </ListItemIcon>
                <ListItemText primary="Dividend Calendar" />
              </ListItemButton>
              <ListItemButton component={Link} to="/economic-calendar" onClick={handleDrawerClose}>
                <ListItemIcon>
                  <EventIcon />
                </ListItemIcon>
                <ListItemText primary="Economic Calendar" />
              </ListItemButton>
            </AccordionDetails>
          </Accordion>

          <Accordion sx={{ background: 'transparent', boxShadow: 'none' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <ListItemIcon>
                <CalculateIcon />
              </ListItemIcon>
              <ListItemText primary="Calculators" />
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              {/*<ListItemButton component={Link} to="/BlackScholesCalculator" onClick={handleDrawerClose}>
                <ListItemIcon>
                  <FunctionsIcon />
                </ListItemIcon>
                <ListItemText primary="Black Scholes Calculator" />
              </ListItemButton>  */}
              <ListItemButton component={Link} to="/stock-return-calculator" onClick={handleDrawerClose}>
                <ListItemIcon>
                  <FunctionsIcon />
                </ListItemIcon>
                <ListItemText primary="Stock Return Calculator" />
              </ListItemButton>
            </AccordionDetails>
          </Accordion>
        </List>
        <Divider />
      </Drawer>
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => setDarkMode(!darkMode)}>
          <ListItemIcon>
            <Brightness4Icon />
          </ListItemIcon>
        </MenuItem>
        <MenuItem component={Link} to="/profile" onClick={handleMenuClose}>
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          Profile</MenuItem>

        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          Log out</MenuItem>
      </Menu>
      <main>{children}</main>
    </div>
  );
};

export default AuthenticatedLayout;