import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import axios from '../config/axiosConfig';
import { DataGrid } from '@mui/x-data-grid';
import { useAuth } from './AuthContext';
import { useTheme } from '@mui/material/styles';
import { 
  CircularProgress, 
  Container, 
  Button, 
  Box, 
  Tabs, 
  Tab, 
} from '@mui/material';
import TitleAndInfo from './TitleAndInfo';
import { exportToCsv } from '../utils/exportToCsv';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ExtendedHoursGainers from './ExtendedHoursGainers';
import ExtendedHoursLosers from './ExtendedHoursLosers';

// Tab Panel component for accessibility
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`market-tabpanel-${index}`}
      aria-labelledby={`market-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

// Helper function for a11y
function a11yProps(index) {
  return {
    id: `market-tab-${index}`,
    'aria-controls': `market-tabpanel-${index}`,
  };
}

const MarketMovers = () => {
  const { user } = useAuth();
  const theme = useTheme();
  const baseUrl = window.location.origin;
  
  // State for main tab navigation
  const [tabValue, setTabValue] = useState(0);
  
  // States for pre-market and after-market sub-tabs
  const [preMarketTab, setPreMarketTab] = useState(0); // 0 for gainers, 1 for losers
  const [afterMarketTab, setAfterMarketTab] = useState(0); // 0 for gainers, 1 for losers
  
  // State for data
  const [marketGainers, setMarketGainers] = useState([]);
  const [marketLosers, setMarketLosers] = useState([]);
  const [marketActives, setMarketActives] = useState([]);
  const [extendedHoursData, setExtendedHoursData] = useState({
    pre: {
      gainers: [],
      losers: []
    },
    after: {
      gainers: [],
      losers: []
    }
  });
  
  // Loading states for each data type
  const [loadingGainers, setLoadingGainers] = useState(true);
  const [loadingLosers, setLoadingLosers] = useState(true);
  const [loadingActives, setLoadingActives] = useState(true);
  const [loadingExtendedHours, setLoadingExtendedHours] = useState(true);
  
  // Content configuration for regular market tabs
  const tabsConfig = [
    {
      id: 'gainers',
      titleTop: "Top Gainers: ",
      title: "Top Stocks of the Day",
      info: "The 'Market Top Gainers' screener lists the stocks with the highest percentage gains in a single day.",
      exportFileName: 'market_gainers.csv',
      data: marketGainers,
      setData: setMarketGainers,
      loading: loadingGainers,
      setLoading: setLoadingGainers,
      endpoint: '/api/stocks/marketgainers',
      renderPercentChange: (value) => (
        <div style={{ color: theme.palette.positive.main }}>
          +{parseFloat(value).toFixed(2)}%
        </div>
      ),
    },
    {
      id: 'losers',
      titleTop: "Biggest Losers: ",
      title: "Identify Top Declining Stocks of the Day",
      info: "The `Market Biggest Losers` screener provides a list of stocks that have experienced the largest percentage losses in value within a single trading day.",
      exportFileName: 'market_losers.csv',
      data: marketLosers,
      setData: setMarketLosers,
      loading: loadingLosers,
      setLoading: setLoadingLosers,
      endpoint: '/api/stocks/marketlosers',
      renderPercentChange: (value) => (
        <div style={{ color: theme.palette.negative.main }}>
          {parseFloat(value).toFixed(2)}%
        </div>
      ),
    },
    {
      id: 'actives',
      titleTop: "Most Active: ",
      title: "Discover High-Volume Stocks of the Day",
      info: "The `Market Most Active` screener provides a list of stocks with the highest trading volume in a single day.",
      exportFileName: 'market_actives.csv',
      data: marketActives,
      setData: setMarketActives,
      loading: loadingActives, 
      setLoading: setLoadingActives,
      endpoint: '/api/stocks/marketactives',
      renderPercentChange: (value) => (
        <div style={{ 
          color: value > 0 
            ? theme.palette.positive.main 
            : value < 0 
              ? theme.palette.negative.main 
              : theme.palette.lightGray.main 
        }}>
          {parseFloat(value).toFixed(2)}%
        </div>
      ),
    }
  ];

  // Handle main tab change
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  
  // Handle pre-market tab change
  const handlePreMarketTabChange = (event, newValue) => {
    setPreMarketTab(newValue);
  };
  
  // Handle after-market tab change
  const handleAfterMarketTabChange = (event, newValue) => {
    setAfterMarketTab(newValue);
  };

  // Fetch all data at once
  const fetchAllData = async () => {
    // Set all loading states to true
    setLoadingGainers(true);
    setLoadingLosers(true);
    setLoadingActives(true);
    setLoadingExtendedHours(true);
    
    try {
      // Make all API calls in parallel
      const [
        gainersResponse, 
        losersResponse, 
        activesResponse, 
        preMarketResponse, 
        afterMarketResponse
      ] = await Promise.all([
        axios.get('/api/stocks/marketgainers'),
        axios.get('/api/stocks/marketlosers'),
        axios.get('/api/stocks/marketactives'),
        axios.get('/api/extended-hours/movers/pre'),
        axios.get('/api/extended-hours/movers/after')
      ]);
      
      // Update state with responses
      if (gainersResponse.data && gainersResponse.data.data) {
        setMarketGainers(gainersResponse.data.data);
      }
      
      if (losersResponse.data && losersResponse.data.data) {
        setMarketLosers(losersResponse.data.data);
      }
      
      if (activesResponse.data && activesResponse.data.data) {
        setMarketActives(activesResponse.data.data);
      }
      
      // Set extended hours data
      setExtendedHoursData({
        pre: {
          gainers: preMarketResponse.data?.data?.gainers || [],
          losers: preMarketResponse.data?.data?.losers || []
        },
        after: {
          gainers: afterMarketResponse.data?.data?.gainers || [],
          losers: afterMarketResponse.data?.data?.losers || []
        }
      });
    } catch (error) {
      console.error('Error fetching market data:', error);
    } finally {
      // Set all loading states to false
      setLoadingGainers(false);
      setLoadingLosers(false);
      setLoadingActives(false);
      setLoadingExtendedHours(false);
    }
  };

  // Fetch all data on component mount
  useEffect(() => {
    fetchAllData();
  }, []);
  
  // Set default values for sub-tabs
  useEffect(() => {
    setPreMarketTab(0);
    setAfterMarketTab(0);
  }, []);

  // Regular market columns configuration
  const getColumns = (tabConfig) => [
    {
      field: 'symbol',
      headerName: 'Company',
      minWidth: 400,
      renderCell: (params) => (
        <div>
          <RouterLink
            to={`${baseUrl}/asset/${params.row.symbol}`}
            style={{ color: theme.palette.primary.main, textDecoration: 'none' }}
          >
            {params.row.symbol}
          </RouterLink>
          <div style={{ fontSize: '0.9em', color: '#888' }}>{params.row.name}</div>
        </div>
      ),
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'changesPercentage',
      headerName: '%Change',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => tabConfig.renderPercentChange(params.value),
      align: 'right',
      headerAlign: 'right',
    },
    {
      field: 'price',
      headerName: 'Price',
      flex: 1,
      minWidth: 150,
      align: 'right',
      headerAlign: 'right',
    },
    {
      field: 'change',
      headerName: 'Change',
      flex: 1,
      minWidth: 150,
      align: 'right',
      headerAlign: 'right',
    },
  ];

  // Handle export for the current tab
  const handleExport = () => {
    const currentTab = tabsConfig[tabValue];
    exportToCsv(
      currentTab.exportFileName,
      getColumns(currentTab),
      currentTab.data
    );
  };

  // Determine overall loading state - only show loader on initial load
  const isInitialLoading = loadingGainers && loadingLosers && loadingActives && loadingExtendedHours;
  
  // Loading state for initial load only
  if (isInitialLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="table-container">
      <Container maxWidth="xl">
        {/* Main tabs navigation */}
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs 
            value={tabValue} 
            onChange={handleTabChange}
            aria-label="market data tabs"
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
          >
            <Tab label="Top Gainers" {...a11yProps(0)} />
            <Tab label="Biggest Losers" {...a11yProps(1)} />
            <Tab label="Most Active" {...a11yProps(2)} />
            <Tab label="Pre-Market" {...a11yProps(3)} />
            <Tab label="After-Market" {...a11yProps(4)} />
          </Tabs>
        </Box>

        {/* Regular Market Tab panels */}
        {tabsConfig.map((tabConfig, index) => (
          <TabPanel key={tabConfig.id} value={tabValue} index={index}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              flexDirection={{ xs: 'column', sm: 'row' }}
              mb={2}
              gap={2}
            >
              <TitleAndInfo 
                titleTop={tabConfig.titleTop} 
                title={tabConfig.title} 
                info={tabConfig.info} 
              />

              {user.plan.name === 'Premium' && (
                <Button 
                  variant="contained" 
                  color="primary" 
                  startIcon={<FileDownloadIcon />} 
                  onClick={handleExport}
                >
                  Export
                </Button>
              )}
            </Box>

            <DataGrid
              rows={tabConfig.data}
              columns={getColumns(tabConfig)}
              getRowId={(row) => row.symbol}
              disableColumnMenu
              pagination
              paginationPosition="middle"
              initialState={{
                pagination: { paginationModel: { pageSize: 10 } },
              }}
              pageSizeOptions={[5, 10, 25]}
              sx={{
                '& .MuiDataGrid-cell': {
                  fontSize: '1rem',
                  fontWeight: 'bold',
                },
                '& .MuiDataGrid-columnHeaders': {
                  fontSize: '0.8rem',
                },
              }}
            />
          </TabPanel>
        ))}
        
        {/* Pre-Market Tab Panel */}
        <TabPanel value={tabValue} index={3}>
          <Box sx={{ width: '100%' }}>
            <Tabs
              value={preMarketTab}
              onChange={handlePreMarketTabChange}
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
              aria-label="pre-market tabs"
            >
              <Tab label="Pre-Market Gainers" />
              <Tab label="Pre-Market Losers" />
            </Tabs>
            
            {loadingExtendedHours ? (
              <Box display="flex" justifyContent="center" my={4}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                {/* Pre-Market Gainers */}
                {preMarketTab === 0 && (
                  <Box mt={3}>
                    <ExtendedHoursGainers 
                      marketSession="pre" 
                      data={extendedHoursData.pre.gainers}
                    />
                  </Box>
                )}
                
                {/* Pre-Market Losers */}
                {preMarketTab === 1 && (
                  <Box mt={3}>
                    <ExtendedHoursLosers 
                      marketSession="pre"
                      data={extendedHoursData.pre.losers} 
                    />
                  </Box>
                )}
              </>
            )}
          </Box>
        </TabPanel>
        
        {/* After-Market Tab Panel */}
        <TabPanel value={tabValue} index={4}>
          <Box sx={{ width: '100%' }}>
            <Tabs
              value={afterMarketTab}
              onChange={handleAfterMarketTabChange}
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
              aria-label="after-market tabs"
            >
              <Tab label="After-Market Gainers" />
              <Tab label="After-Market Losers" />
            </Tabs>
            
            {loadingExtendedHours ? (
              <Box display="flex" justifyContent="center" my={4}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                {/* After-Market Gainers */}
                {afterMarketTab === 0 && (
                  <Box mt={3}>
                    <ExtendedHoursGainers 
                      marketSession="after" 
                      data={extendedHoursData.after.gainers}
                    />
                  </Box>
                )}
                
                {/* After-Market Losers */}
                {afterMarketTab === 1 && (
                  <Box mt={3}>
                    <ExtendedHoursLosers 
                      marketSession="after"
                      data={extendedHoursData.after.losers} 
                    />
                  </Box>
                )}
              </>
            )}
          </Box>
        </TabPanel>
      </Container>
    </div>
  );
};

export default MarketMovers;