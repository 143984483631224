import React, { useState, useEffect } from 'react';
import { Container, Grid, Box, ToggleButtonGroup, ToggleButton, Typography, CircularProgress } from '@mui/material';
import ExtendedHoursGainers from './ExtendedHoursGainers';
import ExtendedHoursLosers from './ExtendedHoursLosers';
import axios from '../config/axiosConfig';

const ExtendedHoursMovers = () => {
    const [marketSession, setMarketSession] = useState('pre');
    const [data, setData] = useState({
        pre: {
            gainers: [],
            losers: []
        },
        after: {
            gainers: [],
            losers: []
        }
    });
    const [loading, setLoading] = useState(true);
    
    // Automatically determine which market session to display based on current time
    useEffect(() => {
        const determineDefaultMarketSession = () => {
            // Get current date and time in Eastern Time (ET) for US markets
            const now = new Date();
            const options = { timeZone: 'America/New_York' };
            const nyTime = new Date(now.toLocaleString('en-US', options));
            
            const hours = nyTime.getHours();
            const minutes = nyTime.getMinutes();
            const dayOfWeek = nyTime.getDay();
            
            // Check if it's a weekday (1-5 represents Monday-Friday)
            const isWeekday = dayOfWeek >= 1 && dayOfWeek <= 5;
            
            if (!isWeekday) {
                // Default to pre-market on weekends
                return 'pre';
            }
            
            // Pre-market hours: 4:00 AM to 9:30 AM ET
            if ((hours === 4 && minutes >= 0) ||
                (hours > 4 && hours < 9) ||
                (hours === 9 && minutes < 30)) {
                return 'pre';
            }
            // After-market hours: 4:00 PM to 8:00 PM ET
            else if ((hours === 16 && minutes >= 0) ||
                (hours > 16 && hours < 20)) {
                return 'after';
            }
            
            // Default to pre-market during regular trading hours
            return 'pre';
        };
        
        setMarketSession(determineDefaultMarketSession());
    }, []);
    
    // Fetch data using the consolidated API endpoint
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                // Use the new consolidated endpoint to fetch both pre and after market data
                const [preResponse, afterResponse] = await Promise.all([
                    axios.get('/api/extended-hours/movers/pre'),
                    axios.get('/api/extended-hours/movers/after')
                ]);
                
                setData({
                    pre: {
                        gainers: preResponse.data?.data?.gainers || [],
                        losers: preResponse.data?.data?.losers || []
                    },
                    after: {
                        gainers: afterResponse.data?.data?.gainers || [],
                        losers: afterResponse.data?.data?.losers || []
                    }
                });
            } catch (error) {
                console.error('Error fetching extended hours data:', error);
            } finally {
                setLoading(false);
            }
        };
        
        fetchData();
    }, []); // Empty dependency array means this runs once on mount
    
    const handleMarketSessionChange = (event, newSession) => {
        if (newSession !== null) {
            setMarketSession(newSession);
        }
    };
    
    return (
        <Container maxWidth="xxl">
            <Box mt={4} mb={4}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Extended Hours Movers
                </Typography>
                <Typography variant="body1" color="textSecondary" paragraph>
                    Track the biggest gainers and losers during extended trading hours.
                </Typography>
                
                <Box display="flex" justifyContent="center" mb={4}>
                    <ToggleButtonGroup
                        value={marketSession}
                        exclusive
                        onChange={handleMarketSessionChange}
                        aria-label="market session"
                    >
                        <ToggleButton value="pre" aria-label="pre-market">
                            Pre-Market
                        </ToggleButton>
                        <ToggleButton value="after" aria-label="after-market">
                            After-Market
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>
                
                {loading ? (
                    <Box display="flex" justifyContent="center" my={4}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={6}>
                            <ExtendedHoursGainers 
                                marketSession={marketSession} 
                                data={data[marketSession].gainers}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ExtendedHoursLosers 
                                marketSession={marketSession}
                                data={data[marketSession].losers} 
                            />
                        </Grid>
                    </Grid>
                )}
            </Box>
        </Container>
    );
};

export default ExtendedHoursMovers;